import React, { useState, useEffect, useContext } from 'react'
import { Router } from '@reach/router'
import PageNotFound from '../../pages/404'
import { default as Layout } from 'layouts/Main'
import SEO from 'components/SEO'
import Sector from 'components/Sector'
import Contacts from 'components/Contacts'
import useSiteMetadata from 'hooks/useSiteMetadata'
import Success from './components/Success'
import Loading from './components/Loading'
import NotificationsContext from 'contexts/notifications'
import Cancel from './components/Cancel'
import memoize from 'utils/memoize'
import useDeferredActive from 'hooks/useDeferredActive'
import StoreContext from 'contexts/store'

const Order = props => {
	const { siteApiUrl } = useSiteMetadata()
	const storeContext = useContext(StoreContext)
	const notificationsContext = useContext(NotificationsContext)
	const [status, setStatus] = useState('empty')
	const [order, setOrder] = useState(null)
	const [retryPaymentUrl, setRetryPaymentUrl] = useState(null)
	const { orderId } = props
	const isLoading = useDeferredActive(true, 500)

	useEffect(() => {
		fetch(`${siteApiUrl}/store/order/${orderId}`).then(async response => {
			const res = await response.json()
			const code = response.status

			if (code === 200 && res.databases) {
				storeContext.clearStore()
				setOrder(res)
				setStatus('success')
			} else if (code === 200 && res.message === 'cancel') {
				setStatus('cancel')
				setRetryPaymentUrl(res.retryPaymentUrl)
			} else if (code === 404 || code === 403) {
				setStatus('not-found')
			} else {
				const { error = {} } = res
				setStatus('cancel')
				notificationsContext.add({
					title: error.title || 'Возникла техническая ошибка',
					text: error.text || 'Мы уже знаем о проблеме. Пожалуйста, попробуйте позднее',
				})
			}
		})
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [orderId, siteApiUrl])

	if (status === 'not-found') return <PageNotFound title="Заказ не найден" />

	return (
		<Layout>
			<SEO title="Ваш заказ — «Парсик»" />

			<Sector>
				{status === 'empty' && !isLoading && <div style={{ height: 313 }} />}
				{status === 'empty' && isLoading && <Loading />}
				{status === 'success' && <Success order={order} />}
				{status === 'cancel' && <Cancel title="Платеж не выполнен" url={retryPaymentUrl} />}
			</Sector>

			<Contacts color="gray" />
		</Layout>
	)
}

const OrderPage = () => (
	<Router>
		<Order path="/order/:orderId" />
		<PageNotFound path="/order/*" />
	</Router>
)

export default memoize(OrderPage)
